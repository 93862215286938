<template>
  <b-card no-body>
    <b-card-header>
      <b-row
        align-v="center"
        class="w-100 m-0"
      >
        <b-col>
          <h4>
            {{ `ยอดที่สรุปแล้ว ${total} รายการ` }}
            
            <b-button
              variant="flat-primary"
              class="btn-icon"
              size="sm"
              @click="fetchData"
            >
              <feather-icon
                icon="RefreshCcwIcon" 
                size="18"
              />
            </b-button>
          </h4>
        </b-col>
        <b-col
          cols="3"
          class="pr-0"
        >
          <v-select
            v-model="selectedStatus"
            label="title"
            :options="statusOptions"
            :clearable="false"
          />
        </b-col>
        <b-col
          cols="3"
          class="pr-0"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="search"
              type="search"
              :placeholder="`ชื่อตัวแทน`"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <div />
    </b-card-header>

    <hr class="m-0">
    <b-table
      ref="billSelectableTable"
      :items="dataList"
      :fields="fields"
      :busy="isFetching"
      responsive
      show-empty
      hover
    >
      <template #cell(startDate)="data">
        {{ data.value | date }}
        -
        {{ data.item.endDate | date }}
      </template>
      <template #cell(dueDate)="data">
        {{ data.value | date }}
      </template>
      <template #cell(totalFee)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(commissionRate)="data">
        {{ data.value }}%
      </template>
      <template #cell(totalAmount)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(outstanding)="data">
        <span :class="data.value > 0?'text-danger': ''">
          {{ data.value | currency }}
        </span>
      </template>
      
      <template #cell(id)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          @click="submitPayment(data.item)"
        >
          <feather-icon icon="PlusIcon" />
          บันทึกการชำระ
        </b-button>
        <b-button
          variant="flat-primary"
          class="btn-icon"
          @click="onViewInvoice(data.item)"
        >
          <feather-icon
            size="22"
            icon="FileTextIcon"
          />
        </b-button>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #empty="">
        <div class="my-5">
          <p class="text-center text-muted">
            ไม่มียอดบิลที่สรุปแล้ว<br>
            ไปที่เมนู <b-button
              variant="flat-primary"
              size="sm"
              @click="onChangeMenu('draft')"
            >
              <feather-icon icon="ClockIcon" />
              ยอดยังไม่สรุป
            </b-button>
            แล้วเลือกสรุปยอดบิลของตัวแทนที่ต้องการ
          </p>
        </div>
      </template>
    </b-table>
    <SubmitPaymentModal :invoice="selectedInvoice" />
    <!-- pagination -->
    <b-card-footer>
      <b-row class="">
        <b-col md="4">
          <page-limit-select
            :value="limit"
            @update="onLimitChange"
          />
        </b-col>
        <b-col>
          <pagination-input
            :per-page="limit"
            :total="total"
            @update="(val) => (currentPage = val)"
          />
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'InvoicesList', 
  components: {
    vSelect,
    SubmitPaymentModal: () => import('./SubmitPaymentModal.vue'),
  },
  data() {
    return {
      currentPage: 0,
      selectedLimit: 20,

      selectedStatus: { title: 'ทุกสถานะการชำระ', value: '0' },
      statusOptions: [
        { title: 'ทุกสถานะการชำระ', value: '0' },
        { title: 'ค้างชำระ', value: '1' },
        { title: 'ชำระครบแล้ว', value: '2' },
      ],

      search: '', 
      onSearchTimeout: null,

      fields: [
         {
          key: 'ref',
          label: 'เลขที่อ้างอิง',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'agentName',
          label: 'ตัวแทน',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'dueDate',
          label: 'ครบกำหนดชำระ',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'startDate',
          label: 'รอบที่สรุป',
          thStyle: {
            minWidth: '210px',
          },
        },
        {
          key: 'totalFee',
          label: 'ค่าธรรมเนียม',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'commissionRate',
          label: 'ส่วนแบ่ง',
          class: 'text-right',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'totalAmount',
          label: 'ยอดที่ต้องเก็บ',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'outstanding',
          label: 'ยอดค้างชำระ',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '230px',
          },
          class: 'text-right',
        },
      ],

      selectedInvoice: null,

    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.payment.isGettingInvoices,
    }),
    ...mapGetters(['invoices']),
    dataList() {
      return this.invoices.data
    },
    pagination() {
      const { totalItems, perPage } = this.invoices;
      return {
        itemsPerPage: perPage || this.selectedLimit,
        totalItems,
      }
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: { 
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedStatus(val) {
      if(val.value) {
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
  },
  created() {
  },
  methods: {
    ...mapActions(['getInvoices', 'saveInvoice']),
    fetchData() {
      this.getInvoices({
        limit: this.selectedLimit,
        skip: this.currentPage,
        search: this.search,
        status: this.selectedStatus.value
      });
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if (limit) {
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    submitPayment(invoice) {
      this.selectedInvoice = invoice
      this.$bvModal.show('submit-payment-modal')
    },
    onChangeMenu(menu) {
      this.$emit('change-menu', menu)
    },
    onViewInvoice(item){
      this.saveInvoice(item)
      this.$router.push(`/invoices/${item.id}`)
    },

  },
}
</script>
 